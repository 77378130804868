.mh__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 4rem;
  height: 7rem;

  .mh__logo__wrapper {
    & img {
      width: 10rem;
    }

    & img:hover {
      cursor: pointer;
    }
  }

  .mh__buttons {
    display: flex;
    justify-content: space-between;
    width: 17rem;
  }
}

.link {
  text-decoration: none;
  color: #1976d2;
}

.active_link {
  text-decoration: none;
  color: #1976d2;
  border-bottom: 2px #1976d2 solid;
}

.auth__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 4rem;
  height: 4rem;

  .auth__logo__wrapper {
    height: 2rem;
    width: 17rem;
    z-index: 1;

    & img {
      width: 12rem;
    }

    & img:hover {
      cursor: pointer;
    }

  }

  .links {

    & a {
      padding: .2rem;
      text-decoration: none;
      color: #1976d2;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
}

.right_wrapper {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;

  .auth__buttons {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 10rem;

    & a {
      padding: .2rem;
      text-decoration: none;
      color: #1976d2;
      text-transform: uppercase;
      font-weight: 500;
    }
  }

  .project_name_wrapper {
    color: #1976d2;
    font-weight: 500;
    padding: .2rem;
  }
}

.title__wrapper {
  display: flex;
  margin-bottom: .5rem;

  .arrow {
    height: 2rem;
    width: 2rem;
    margin-right: 2rem;

    &:hover {
      cursor: pointer;
      color: #1976d2;
    }
  }
}

@media (max-width: 480px) {
  .right_wrapper {
    display: flex;
    flex-direction: column;

    .auth__buttons {
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 10rem;

      & a {
        padding: .2rem;
        text-decoration: none;
        color: #1976d2;
        text-transform: uppercase;
        font-weight: 500;
      }
    }

    .project_name_wrapper {
      color: #1976d2;
      font-weight: 500;
      padding: .2rem;
    }
  }

  .auth__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-left: 4rem;
    padding-right: 4rem;
    height: 15rem;

    .auth__logo__wrapper {
      width: 17rem;

      & img {
        width: 12rem;
      }

      & img:hover {
        cursor: pointer;
      }
    }

    .links {

      & a {
        padding: .2rem;
        text-decoration: none;
        color: #1976d2;
        text-transform: uppercase;
        font-weight: 500;
      }
    }
  }
}