.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;

  & img {
    width: 2rem;
    height: 2rem;
    background-position: center;
    background-size: contain;

  }
}

.orange {
  filter: brightness(0) saturate(100%) invert(39%) sepia(89%) saturate(2720%) hue-rotate(1deg) brightness(104%) contrast(104%);
}

.red {
  filter: brightness(0) saturate(100%) invert(15%) sepia(98%) saturate(7049%) hue-rotate(7deg) brightness(99%) contrast(117%);
}

.green {
  filter: brightness(0) saturate(100%) invert(38%) sepia(83%) saturate(1954%) hue-rotate(92deg) brightness(100%) contrast(108%);
}

.blue {
  filter: brightness(0) saturate(100%) invert(18%) sepia(96%) saturate(2665%) hue-rotate(201deg) brightness(89%) contrast(102%);
}

.pink {
  filter: brightness(0) saturate(100%) invert(19%) sepia(75%) saturate(5304%) hue-rotate(279deg) brightness(69%) contrast(123%);
}