.user_actions__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.user_actions__form {
  width: 20rem;
  height: 25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .user_actions__form__select {
    width: 16rem;
    margin: 1rem;

    & > * {
      margin: 0.5rem;
    }
  }
}

.user_actions__card {
  width: 25rem;
  margin-bottom: 1rem;
}

.user_actions__sort_wrapper {
  width: 100%;
  height: 20rem;
  display: flex;
}

.transform__icon {
  transform: rotate(180deg);
}
