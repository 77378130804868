.right__content {
  display: flex;
  flex-grow: 2;
  align-items: flex-start;
  flex-direction: column;
  //-webkit-animation: slide-in-fwd-right 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  //animation: slide-in-fwd-right 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

  .submit__button {
    margin: 0 1rem;
    width: 10rem;
  }
}

.planting_img_wrapper {
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-right: 1rem;
  height: 80vh;
  width: 50vw;

  & img {
    margin: 1rem;
    max-width: 50vw;
    max-height: 80vh;
  }
}

.image_with_icons {
  display: flex;
  position: relative;

  .images_icons {
    display: flex;
    position: absolute;
    top: 2rem;
    justify-content: space-between;
    right: 2rem;
    border: 2px solid #1480ec;
    border-radius: 5px;
    background: rgb(255, 255, 255);

    & > * {
      margin: 0.2rem 0.4rem;
      cursor: pointer;
    }
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-7-18 16:34:13
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-fwd-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-fwd-right {
  0% {
    -webkit-transform: translateZ(-1400px) translateX(1000px);
    transform: translateZ(-1400px) translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateX(0);
    transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-right {
  0% {
    -webkit-transform: translateZ(-1400px) translateX(1000px);
    transform: translateZ(-1400px) translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateX(0);
    transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}
